import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';

class premiumListing extends Component {

  render(props) {
  	const bgImage = this.props.data.file.childImageSharp.fluid.src;

  	return(

  		<Layout pageName="Premium listing">
	      <Helmet>
	        <title>Best in Brighton premium listing</title>
	      </Helmet>
	      <div
	        className="page-hero"
	        style={{
	          backgroundImage: `url(${bgImage})`,
	        }}
	      >
	        <h1>Best in Brighton premium listing</h1>
	      </div>

	      <div className="container container-content">
	         <h2>What is a premium listing?</h2>
           <p>Listings on Best in Brighton are collated by locals, if your company is listed you can upgrade your listing to a premium listing, allowing you to provide more information, links, photos and become a highlighted listing.</p>
          
           <p>For monthly payment of £9.99 you will be able to upgrade your listing to include:</p>

           <ul>
             <li>hightlighted listing</li>
             <li>list special offers</li>
             <li>a gallery of multiple images</li>
             <li>addition of social links</li>
             <li>additional information / description </li>
           </ul>

          <h2>How do I upgrade to a premium listing?</h2>

          <p>To upgrade to a premium listing please contact us by emailing: <a href="mailto:hello@bestinbrighton.co.uk">hello@bestinbrighton.co.uk</a>.</p>
	      </div>
  		</Layout>
  	);
  }
}


export default premiumListing

export const query = graphql`
  query {
    file(relativePath: { eq: "banner-images/about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, fit: COVER) {
        	src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`